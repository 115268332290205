<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    Cerrando Sesion
  </v-container>
</template>

<script>
  const Cookies = require('js.cookie')

  export default {
    beforeMount () {
      Cookies.remove('jwt')
      Cookies.remove('username')
      this.$router.push('/login')
    },
  }
</script>
